import React from "react";

export default function Contact() {
  return (
    <body>
      <div className="page-nav no-margin row">
        <div className="container">
          <div className="row">
            <h2>Contact Us</h2>
            <ul>
              <li>
                <a href="#">
                  <i className="fas fa-home"></i> Home
                </a>
              </li>
              <li>
                <i className="fas fa-angle-double-right"></i> Contact US
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div style={{ marginTop: 0 }} className="row no-margin">
        <iframe
          style={{ width: "100%" }}
          src="https://maps.google.com/maps?q=%20preceta%20aljubarota,%20S%C3%A3o%20Jo%C3%A3o%20da%20Talha,%20Portugal&t=&z=13&ie=UTF8&iwloc=&output=embed"
          height="450"
          frameBorder="0"
          allowFullScreen
        >
          {" "}
        </iframe>
      </div>
      <a href="https://embedgooglemap.net/124/"></a>
      <a href="https://www.embedgooglemap.net"></a>

      <div className="row contact-rooo no-margin">
        <div className="container">
          <div className="row">
            <div style={{ padding: "20px" }} className="col-sm-7">
              <h2>Contact Form</h2>
              <br />
              <div className="row cont-row">
                <div className="col-sm-3">
                  <label>Enter Name </label>
                  <span>:</span>
                </div>
                <div className="col-sm-8">
                  <input
                    type="text"
                    placeholder="Enter Name"
                    name="name"
                    className="form-control input-sm"
                  />
                </div>
              </div>
              <div className="row cont-row">
                <div className="col-sm-3">
                  <label>Email Address </label>
                  <span>:</span>
                </div>
                <div className="col-sm-8">
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter Email Address"
                    className="form-control input-sm"
                  />
                </div>
              </div>
              <div className="row cont-row">
                <div className="col-sm-3">
                  <label>Mobile Number</label>
                  <span>:</span>
                </div>
                <div className="col-sm-8">
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter Mobile Number"
                    className="form-control input-sm"
                  />
                </div>
              </div>
              <div className="row cont-row">
                <div className="col-sm-3">
                  <label>Enter Message</label>
                  <span>:</span>
                </div>
                <div className="col-sm-8">
                  <textarea
                    rows="5"
                    placeholder="Enter Your Message"
                    className="form-control input-sm"
                  ></textarea>
                </div>
              </div>
              <div style={{ marginTop: 10 }} className="row">
                <div style={{ paddingTop: 10 }} className="col-sm-3">
                  <label></label>
                </div>
                <div className="col-sm-8">
                  <button className="btn btn-primary btn-sm">
                    Send Message
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-5">
              <div style={{ margin: 50 }} className="serv">
                <h2 style={{ marginTop: 10 }}>Address</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}

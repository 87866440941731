import React, { useEffect, useState } from "react";
import client from "../client";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import BlockContent from "@sanity/block-content-to-react";

export default function Blog() {
  const [posts, setPosts] = useState([]);
  const [authors, setAuthors] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch blog posts
    client
      .fetch(
        `*[_type == "post"] {
          title,
          slug,
          body,
          publishedAt,
          mainImage {
            asset -> {
              _id,
              url
            },
            alt,
          },
          author->{
            name,
            image {
              asset->{
                url
              }
            }
          }
        } | order(publishedAt desc)`
      )
      .then((data) => {
        setPosts(data);
        setLoading(false);
      })
      .catch(console.error);

    // Fetch authors
    client
      .fetch(
        `*[_type == "author"]{
        name,
        image {
          asset->{
            url
          }
        }
      }`
      )
      .then((data) => {
        const authorData = {};
        data.forEach((author) => {
          authorData[author.name] = author;
        });
        setAuthors(authorData);
      })
      .catch(console.error);
  }, []);

  const serializers = {
    types: {
      // Handle images with `url` field
      image: ({ node }) => (
        <img src={node.asset.url} alt={node.alt} style={{ maxWidth: "100%" }} />
      ),
    },
  };

  return (
    <div>
      <div className="page-nav no-margin row">
        {/* Your navigation code here */}
      </div>
      {loading ? (
        <section className="our-blog">
          {/* ... Loader code remains the same ... */}
        </section>
      ) : (
        <div className="our-blog">
          <div className="container">
            <div className="row">
              {posts.map((post, index) => (
                <div className="col-md-4 col-sm-6" key={post.slug.current}>
                  <Link to={`/${post.slug.current}`}>
                    <div className="single-blog">
                      {post.mainImage && (
                        <figure>
                          <img
                            src={post.mainImage.asset.url}
                            alt={post.title}
                          />
                        </figure>
                      )}
                      <p class="text-secondary mb-1">
                        {new Date(post.publishedAt).toDateString()}
                      </p>

                      <div className="blog-detail">
                        <small className="author-info">
                          {authors[post.author] &&
                            authors[post.author.name].image && (
                              <img
                                className="author-image"
                                src={authors[post.author.name].image.asset.url}
                              />
                            )}
                          By {authors[post.author.name].name}
                        </small>
                        <h4>{post.title}</h4>
                        <p
                          style={{
                            height: "100px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <BlockContent
                            blocks={post.body}
                            serializers={serializers} // Use the serializers
                          />
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import React from "react";
import { motion } from "framer-motion";

export default function Home() {
  return (
    <motion.body initial={{ y: -50 }} animate={{ y: 0 }}>
      <div class="slider">
        <div class="owl-carousel">
          <div class="item">
            <div class="slider-img">
              <img
                src="assets/images/events/Screenshot 2023-10-30 at 16.25.12.png"
                alt=""
              />
            </div>
            <div class="container">
              <div class="row">
                <div class="col-lg-offset-2 col-lg-8 col-md-offset-2 col-md-8 col-sm-12 col-xs-12">
                  <div class="slider-captions">
                    <h1 class="slider-title">
                      Together we can make the difference.
                    </h1>
                    <p class="slider-text hidden-xs">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="item">
            <div className="slider-img">
              <video width="100%" height="auto" autoPlay muted loop>
                <source src="1030.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
          <div class="item">
            <div class="slider-img">
              <img src="assets/images/slider/slider-1.jpg" alt="" />
            </div>
            <div class="container">
              <div class="row">
                <div class="col-lg-offset-2 col-lg-8 col-md-offset-2 col-md-8 col-sm-12 col-xs-12">
                  <div class="animated bounceInDown slider-captions">
                    <h1 class="slider-title">
                      Union of Refugees in Portugal-UREP
                    </h1>
                    <p class="slider-text hidden-xs">
                      bridge the gap between those in need and the resources and
                      support they require. <br />
                      Their mission is to alleviate suffering
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="slider-img">
              <img
                src="assets/images/events/Screenshot 2023-10-30 at 16.30.05.png"
                alt=""
              />
            </div>
            <div class="container">
              <div class="row">
                <div class="col-lg-offset-2 col-lg-8 col-md-offset-2 col-md-8 col-sm-12 col-xs-12">
                  <div class="animated bounceInDown slider-captions">
                    <h1 class="slider-title">
                      Union of Refugees in Portugal-UREP
                    </h1>
                    <p class="slider-text hidden-xs">
                      bridge the gap between those in need and the resources and
                      support they require. <br />
                      Their mission is to alleviate suffering
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="about-us container-fluid">
        <div class="container">
          <div class="row natur-row no-margin w-100">
            <div class="text-part col-md-6">
              <h2>About Us | Quem Somos?</h2>
              <p>
                The Union of Refugees in Portugal – UREP was created on May 10,
                2013, formed by refugee communities in Portugal, with the aim of
                promoting and integrating refugees into Portuguese society.
              </p>
              <p>
                With several buffs of experience in intercultural mediation with
                refugees from different origins to understand their needs and
                committed to contributing to the well-being of all refugees in
                Portugal. UREP, through its actions, aims to understand the
                needs of refugees and collaborate with other responsible
                institutions to develop effective responses, contributing to a
                well-defined and successfully implemented strategy.
              </p>

              <p>
                União de Refugiados Em Portugal - UREP foi criada a 10 de maio
                de 2013, e oficializada em 31-10-2016. É formada pela comunidade
                de refugiados em Portugal, com o objetivo de promover e integrar
                os refugiados na sociedade portuguesa. Com variados lustres de
                experiência na mediação intercultural com refugiados de
                diferentes origens para a compreensão das suas necessidades
                sempre empenhados em contribuir para o bem-estar de todos os
                refugiados em Portugal. A UREP, através da sua atuação, pretende
                criar a ponte entre as necessidades dos refugiados e colaborar
                com outras instituições responsáveis para o desenvolvimento de
                respostas efetivas, contribuindo para uma estratégia bem
                definida e implementada com sucesso.
              </p>
            </div>
            <div class="image-part col-md-6">
              <div class="about-quick-box row">
                <div class="col-md-6">
                  <div class="about-qcard">
                    <i class="fas fa-user"></i>
                    <p>Becom a Volunteer</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="about-qcard">
                    <i class="fas fa-search-dollar red"></i>
                    <p>Quick Fundrais</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="about-qcard">
                    <i class="fas fa-donate yell"></i>
                    <p>Giv Donation</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="about-qcard">
                    <i class="fas fa-hands-helping blu"></i>
                    <p>Help Someone</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="container-fluid mission-vision">
        <div class="container">
          <div class="row mission">
            <div class="col-md-6 mv-det">
              <h1>Our Mission | Missão</h1>
              <p>
                A União dos Refugiados em Portugal - UREP, visa dar aos
                refugiados um atalho para a sociedade portuguesa. Através da
                nossa comunidade, combinamos refugiados com profissionais,
                organizações e empresas locais na região, com base em
                profissões, habilidades e expertises. A nossa comunidade
                desenvolve o networking adequado a estes cidadãos para que
                realizem as suas ambições profissionais. A nossa missão passa
                por orientar, capacitar, e facilitar a integração no mercado de
                trabalho português, para que construam uma vida de sucesso em
                Portugal.
              </p>
            </div>
            <div class="col-md-6 mv-img">
              <img src="assets/images/events/2.jpeg " alt="" />
            </div>
          </div>
          <div class="row vision">
            <div class="col-md-6 mv-img">
              <img src="assets/images/events/1.jpeg" alt="" />
            </div>
            <div class="col-md-6 mv-det">
              <h1> Our vision | Visão</h1>
              <p>
                A União dos Refugiados em Portugal - UREP visa apoiar os
                refugiados no exercício da cidadania através da mediação
                intercultural de forma a garantir a sua plena integração,
                providenciando o acesso a meios de inclusão social que os
                dignifiquem em harmonia com a legalidade, acreditando na unidade
                e no trabalho interdisciplinar como resposta aos desafios, que
                exigem um acompanhamento direto para solucionar a panóplia de
                problemáticas sociais que as barreiras quotidianas lhes antepõem
                como obstáculos e que exigem solução constante.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="our-team team-11">
        <div class="container">
          <div class="session-title row">
            <h2>Meet our Team</h2>
          </div>
          <div class="row team-row">
            <div class="col-md-4 col-sm-6">
              <div class="single-usr">
                <img
                  src="assets/images/all/WhatsApp Image 2024-01-10 at 20.26.29.jpeg"
                  alt=""
                />
                <div class="det-o">
                  <h4>Mubarak</h4>
                  <i>Tesoureiro </i>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="single-usr">
                <img
                  src="assets/images/all/WhatsApp Image 2024-01-10 at 16.39.46.jpeg"
                  alt=""
                />
                <div class="det-o">
                  <h4>Ahmed Abdalla</h4>
                  <i>Presidente</i>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="single-usr">
                <img
                  src="assets/images/all/WhatsApp Image 2024-01-10 at 20.26.30.jpeg"
                  alt=""
                />
                <div class="det-o">
                  <h4>Alexsander kweh</h4>
                  <i>Vogal</i>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="single-usr">
                <img
                  src="assets/images/all/WhatsApp Image 2024-01-10 at 16.39.46 (1).jpeg"
                  alt=""
                />
                <div class="det-o">
                  <h4>Adam Labor</h4>
                  <i>Vice President</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="doctor-message">
        <div class="inner-lay">
          <div class="container">
            <div class="row session-title">
              <h2>Our Achievemtns in Numbers</h2>
              <p>
                We can talk for a long time about advantages of our Dental
                clinic before other medical treatment facilities. But you can
                read the following facts in order to make sure of all pluses of
                our clinic:
              </p>
            </div>
            <div class="row">
              <div class="col-sm-3 numb">
                <h3>12+</h3>
                <span>YEARS OF EXPEREANCE</span>
              </div>
              <div class="col-sm-3 numb">
                <h3>1812+</h3>
                <span>HAPPY CHILDRENS</span>
              </div>
              <div class="col-sm-3 numb">
                <h3>52+</h3>
                <span>EVENTS</span>
              </div>
              <div class="col-sm-3 numb">
                <h3>48+</h3>
                <span>FUNT RAISED</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.body>
  );
}

import React from "react";
function Gallery() {
  return (
    <body>
      <div class="page-nav no-margin row">
        <div class="container">
          <div class="row">
            <h2>Our Gallery</h2>
            <ul>
              <li>
                <a href="#">
                  <i class="fas fa-home"></i> Home
                </a>
              </li>
              <li>
                <i class="fas fa-angle-double-right"></i> Gallery
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div id="portfolio" class="gallery">
        <div class="container">
          <div class="row">
            <br />
            <div class="gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter hdpe">
              <img src="assets/images/events/3.jpeg" class="img-responsive" />
            </div>
            <div class="gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter hdpe">
              <img
                src="assets/images/events/30714281_1221666361301368_8765206201581922103_n.jpg"
                class="img-responsive"
              />
            </div>{" "}
            <div class="gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter hdpe">
              <img
                src="assets/images/events/85-DSC06824.jpg"
                class="img-responsive"
              />
            </div>{" "}
            <div class="gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter hdpe">
              <img
                src="assets/images/events/IMG_1377.JPG"
                class="img-responsive"
              />
            </div>
            <div class="gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter hdpe">
              <img
                src="assets/images/events/IMG_2566.JPG"
                class="img-responsive"
              />
            </div>
            <div class="gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter hdpe">
              <img src="assets/images/events/6.jpeg" class="img-responsive" />
            </div>
            <div class="gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter irrigation">
              <img src="assets/images/events/8.jpeg" class="img-responsive" />
            </div>
            <div class="gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter spray">
              <img src="assets/images/events/7.jpeg" class="img-responsive" />
            </div>
            <div class="gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter spray">
              <img src="assets/images/events/8.jpeg" class="img-responsive" />
            </div>
            <div class="gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter irrigation">
              <img
                src="assets/images/events/image_06.jpg"
                class="img-responsive"
              />
            </div>
            <div class="gallery_product col-lg-3 col-md-4 col-sm-3 col-xs-6 filter irrigation">
              <img
                src="assets/images/events/image_07.jpg"
                class="img-responsive"
              />
            </div>
            <div class="gallery_product col-lg-3 col-md-4 col-sm-3 col-xs-6 filter irrigation">
              <img
                src="assets/images/gallery/WhatsApp Image 2023-10-30 at 13.13.10.jpeg"
                class="img-responsive"
              />
            </div>
            <div class="gallery_product col-lg-3 col-md-4 col-sm-3 col-xs-6 filter irrigation">
              <img
                src="assets/images/gallery/WhatsApp Image 2023-10-30 at 13.13.11.jpeg"
                class="img-responsive"
              />
            </div>
            <div class="gallery_product col-lg-3 col-md-4 col-sm-3 col-xs-6 filter irrigation">
              <img
                src="assets/images/gallery/WhatsApp Image 2023-10-30 at 13.13.12.jpeg"
                class="img-responsive"
              />
            </div>
            <div class="gallery_product col-lg-3 col-md-4 col-sm-3 col-xs-6 filter irrigation">
              <img
                src="assets/images/gallery/WhatsApp Image 2023-10-30 at 13.13.15.jpeg"
                class="img-responsive"
              />
            </div>
            <div class="gallery_product col-lg-3 col-md-4 col-sm-3 col-xs-6 filter irrigation">
              <img
                src="assets/images/gallery/WhatsApp Image 2023-10-30 at 13.15.17.jpeg"
                class="img-responsive"
              />
            </div>
            <div class="gallery_product col-lg-3 col-md-4 col-sm-3 col-xs-6 filter irrigation">
              <img
                src="assets/images/gallery/WhatsApp Image 2023-10-30 at 13.15.18.jpeg"
                class="img-responsive"
              />
            </div>
            <div class="gallery_product col-lg-3 col-md-4 col-sm-3 col-xs-6 filter irrigation">
              <img
                src="assets/images/gallery/WhatsApp Image 2023-10-30 at 13.15.20.jpeg"
                class="img-responsive"
              />
            </div>
            <div class="gallery_product col-lg-3 col-md-4 col-sm-3 col-xs-6 filter irrigation">
              <img
                src="assets/images/gallery/WhatsApp Image 2023-10-30 at 13.16.50.jpeg"
                class="img-responsive"
              />
            </div>
            <div class="gallery_product col-lg-3 col-md-4 col-sm-3 col-xs-6 filter irrigation">
              <img
                src="assets/images/gallery/WhatsApp Image 2023-10-30 at 13.16.52.jpeg"
                class="img-responsive"
              />
            </div>
            <div class="gallery_product col-lg-3 col-md-4 col-sm-3 col-xs-6 filter irrigation">
              <img
                src="assets/images/gallery/WhatsApp Image 2023-10-30 at 14.19.32.jpeg"
                class="img-responsive"
              />
            </div>
            <div class="gallery_product col-lg-3 col-md-4 col-sm-3 col-xs-6 filter irrigation">
              <img
                src="assets/images/gallery/WhatsApp Image 2023-10-30 at 14.19.39.jpeg"
                class="img-responsive"
              />
            </div>{" "}
            <div class="gallery_product col-lg-3 col-md-4 col-sm-3 col-xs-6 filter irrigation">
              <img
                src="assets/images/gallery/WhatsApp Image 2023-10-30 at 14.19.39.jpeg"
                class="img-responsive"
              />
            </div>{" "}
            <div class="gallery_product col-lg-3 col-md-4 col-sm-3 col-xs-6 filter irrigation">
              <img
                src="assets/images/gallery/WhatsApp Image 2023-10-30 at 14.33.54.jpeg"
                class="img-responsive"
              />
            </div>{" "}
            <div class="gallery_product col-lg-3 col-md-4 col-sm-3 col-xs-6 filter irrigation">
              <img
                src="assets/images/gallery/WhatsApp Image 2023-10-30 at 14.19.36.jpeg"
                class="img-responsive"
              />
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}

export default Gallery;

import React from "react";

function partners() {
  return (
    <section>
      <div class="page-nav no-margin row">
        <div class="container">
          <div class="row">
            <h2> Who we work with</h2>

            <ul>
              <li>
                <a href="#">
                  <i class="fas fa-home"></i> Home
                </a>
              </li>
              <li>
                <i class="fas fa-angle-double-right"></i> Work
              </li>
            </ul>
          </div>
        </div>
      </div>

      <section id="clients" class="section-bg">
        <div class="container">
          <div class="row no-gutters clients-wrap clearfix wow fadeInUp">
            <div class="col-lg-3 col-md-4 col-xs-6">
              <a class="client-logo" href="https://cpr.pt/" target="_blank">
                <img
                  src="https://cpr.pt/wp-content/uploads/2015/12/CPR30.png"
                  class="img-fluid"
                  alt=""
                />
                <div class="client-name">
                  Conselho portugues para os refugiados
                </div>
              </a>
            </div>
            <div class="col-lg-3 col-md-4 col-xs-6">
              <a class="client-logo" href="https://cpr.pt/" target="_blank">
                <img
                  src="https://cpr.pt/wp-content/uploads/2015/12/CPR30.png"
                  class="img-fluid"
                  alt=""
                />
                <div class="client-name">
                  Conselho portugues para os refugiados
                </div>
              </a>
            </div>
            <div class="col-lg-3 col-md-4 col-xs-6">
              <a class="client-logo" href="https://cpr.pt/" target="_blank">
                <img
                  src="https://cpr.pt/wp-content/uploads/2015/12/CPR30.png"
                  class="img-fluid"
                  alt=""
                />
                <div class="client-name">
                  Conselho portugues para os refugiados
                </div>
              </a>
            </div>
            <div class="col-lg-3 col-md-4 col-xs-6">
              <a class="client-logo" href="https://cpr.pt/" target="_blank">
                <img
                  src="https://cpr.pt/wp-content/uploads/2015/12/CPR30.png"
                  class="img-fluid"
                  alt=""
                />
                <div class="client-name">
                  Conselho portugues para os refugiados
                </div>
              </a>
            </div>
            <div class="col-lg-3 col-md-4 col-xs-6">
              <a class="client-logo" href="https://cpr.pt/" target="_blank">
                <img
                  src="https://cpr.pt/wp-content/uploads/2015/12/CPR30.png"
                  class="img-fluid"
                  alt=""
                />
                <div class="client-name">
                  Conselho portugues para os refugiados
                </div>
              </a>
            </div>
            <div class="col-lg-3 col-md-4 col-xs-6">
              <a class="client-logo" href="https://cpr.pt/" target="_blank">
                <img
                  src="https://cpr.pt/wp-content/uploads/2015/12/CPR30.png"
                  class="img-fluid"
                  alt=""
                />
                <div class="client-name">
                  Conselho portugues para os refugiados
                </div>
              </a>
            </div>
            <div class="col-lg-3 col-md-4 col-xs-6">
              <a class="client-logo" href="https://cpr.pt/" target="_blank">
                <img
                  src="https://cpr.pt/wp-content/uploads/2015/12/CPR30.png"
                  class="img-fluid"
                  alt=""
                />
                <div class="client-name">
                  Conselho portugues para os refugiados
                </div>
              </a>
            </div>
            <div class="col-lg-3 col-md-4 col-xs-6">
              <a class="client-logo" href="https://cpr.pt/" target="_blank">
                <img
                  src="https://cpr.pt/wp-content/uploads/2015/12/CPR30.png"
                  class="img-fluid"
                  alt=""
                />
                <div class="client-name">
                  Conselho portugues para os refugiados
                </div>
              </a>
            </div>
            <div class="col-lg-3 col-md-4 col-xs-6">
              <a class="client-logo" href="https://cpr.pt/" target="_blank">
                <img
                  src="https://cpr.pt/wp-content/uploads/2015/12/CPR30.png"
                  class="img-fluid"
                  alt=""
                />
                <div class="client-name">
                  Conselho portugues para os refugiados
                </div>
              </a>
            </div>
            <div class="col-lg-3 col-md-4 col-xs-6">
              <a class="client-logo" href="https://cpr.pt/" target="_blank">
                <img
                  src="https://cpr.pt/wp-content/uploads/2015/12/CPR30.png"
                  class="img-fluid"
                  alt=""
                />
                <div class="client-name">
                  Conselho portugues para os refugiados
                </div>
              </a>
            </div>
            <div class="col-lg-3 col-md-4 col-xs-6">
              <a class="client-logo" href="https://cpr.pt/" target="_blank">
                <img
                  src="https://cpr.pt/wp-content/uploads/2015/12/CPR30.png"
                  class="img-fluid"
                  alt=""
                />
                <div class="client-name">
                  Conselho portugues para os refugiados
                </div>
              </a>
            </div>
            <div class="col-lg-3 col-md-4 col-xs-6">
              <a class="client-logo" href="https://cpr.pt/" target="_blank">
                <img
                  src="https://cpr.pt/wp-content/uploads/2015/12/CPR30.png"
                  class="img-fluid"
                  alt=""
                />
                <div class="client-name">
                  Conselho portugues para os refugiados
                </div>
              </a>
            </div>
            <div class="col-lg-3 col-md-4 col-xs-6">
              <a class="client-logo" href="https://cpr.pt/" target="_blank">
                <img
                  src="https://cpr.pt/wp-content/uploads/2015/12/CPR30.png"
                  class="img-fluid"
                  alt=""
                />
                <div class="client-name">
                  Conselho portugues para os refugiados
                </div>
              </a>
            </div>
            <div class="col-lg-3 col-md-4 col-xs-6">
              <div class="client-logo">
                <img
                  src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1559460224/cropped-cropped-20170720-lucuLogo-squ2-e1500543540803.png"
                  class="img-fluid"
                  alt=""
                />
                <div class="client-name">Abof</div>
              </div>
            </div>

            <div class="col-lg-3 col-md-4 col-xs-6">
              <div class="client-logo">
                <img
                  src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1559460269/104840a62d46c05d285762857fecb61a.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>

            <div class="col-lg-3 col-md-4 col-xs-6">
              <div class="client-logo">
                <img
                  src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1559460358/client-4.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>

            <div class="col-lg-3 col-md-4 col-xs-6">
              <div class="client-logo">
                <img
                  src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1559460379/client-5.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>

            <div class="col-lg-3 col-md-4 col-xs-6">
              <div class="client-logo">
                <img
                  src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1559460398/client-6.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>

            <div class="col-lg-3 col-md-4 col-xs-6">
              <div class="client-logo">
                <img
                  src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1559460418/client-7.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>

            <div class="col-lg-3 col-md-4 col-xs-6">
              <div class="client-logo">
                <img
                  src="https://bootstrapmade.com/demo/themes/NewBiz/img/clients/client-8.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default partners;

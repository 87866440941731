import React from "react";

export default function Services() {
  return (
    <body>
      <div class="page-nav no-margin row">
        <div class="container">
          <div class="row">
            <h2>What we do</h2>
            <ul>
              <li>
                <a href="#">
                  <i class="fas fa-home"></i> Home
                </a>
              </li>
              <li>
                <i class="fas fa-angle-double-right"></i>Services
              </li>
            </ul>
          </div>
        </div>
      </div>

      <section class="events">
        <div class="container">
          <div class="event-ro row">
            <div class="col-md-4 col-sm-6">
              <div class="event-box">
                <img src="assets/images/events/66-DSC07142.jpg" alt="" />
                <h4>Intercultural Mediation:</h4>

                <p class="desic">
                  UREP offers intercultural mediation services to refugees from
                  diverse backgrounds. This service involves experienced
                  mediators who facilitate communication between refugees and
                  local institutions, helping them to understand and address
                  their unique needs.
                </p>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="event-box">
                <img src="assets/images/events/IMG_3113.JPG" alt="" />
                <h4>Integration Strategy Development</h4>

                <p class="desic">
                  UREP collaborates with responsible institutions to develop
                  effective integration strategies for refugees in Portugal.
                  They work to identify the needs of refugees and provide input
                  to create well-defined, successful integration plans.
                </p>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="event-box">
                <img
                  src="assets/images/events/WhatsApp Image 2023-10-30 at 14.19.30.jpeg"
                  alt=""
                />
                <h4>Multidisciplinary Team Building</h4>

                <p class="desic">
                  {" "}
                  UREP has established a multidisciplinary team dedicated to
                  improving the integration indicators for refugees. This team
                  works together to bring transparency to the integration
                  process and implement various actions and debates with input
                  from different political and social groups.
                </p>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="event-box">
                <img src="assets/images/events/IMG_E3396.JPG" alt="" />
                <h4>Citizenship Support:</h4>

                <p class="desic">
                  : UREP's services include supporting refugees in the exercise
                  of citizenship. Through intercultural mediation, they help
                  refugees understand their rights and responsibilities,
                  ensuring their full integration and access to social inclusion
                  resources that comply with legal standards.
                </p>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="event-box">
                <img src="assets/images/events/IMG_2485.JPG" alt="" />
                <h4>Social Inclusion</h4>

                <p class="desic">
                  UREP strives to provide refugees with access to social
                  inclusion opportunities that dignify them and promote harmony
                  with legal requirements. They work to break down barriers and
                  obstacles that refugees face in their everyday lives, seeking
                  constant solutions to social problems.
                </p>
              </div>
            </div>

            <div class="col-md-4 col-sm-6">
              <div class="event-box">
                <img src="assets/images/events/IMG_3134.JPG" alt="" />
                <h4>Advocacy and Unity Building</h4>

                <p class="desic">
                  UREP believes in the power of unity and interdisciplinary work
                  to address the challenges faced by refugees. They engage in
                  advocacy efforts and promote collaboration among various
                  stakeholders to directly address and resolve social issues
                  affecting refugees in Portugal.
                </p>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="event-box">
                <img
                  src="assets/images/events/WhatsApp Image 2023-10-30 at 14.20.35.jpeg"
                  alt=""
                />
                <h4>Job searching</h4>

                <p class="desic">
                  Em primeiro lugar é imperativo que se faça uma primeira
                  entrevista de diagnóstico, para conhecer as qualificações e
                  talentos dos refugiados e seus objetivos; Apreender se já tem
                  ou não Curriculum Vitae, adequá-lo ao mercado de trabalho
                  português e ao modelo europeu; Criar redes profissionais
                  relevantes entre cidadãos portugueses e refugiados numa fase
                  inicial obter insights sobre possibilidades de voluntariado,
                  estágios e outras oportunidades de desenvolvimento, estimular
                  o empreendedorismo. O trabalho voluntário e os estágios são
                  altamente recomendados para os refugiados, permite que se
                  mantenham ativos e ganhem experiência com a sociedade e o
                  mercado de trabalho portugueses.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer class="footer">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <h2>About Us</h2>
              <p>
                Develop a multidisciplinary team that helps bring means and
                transparency to integration processes through actions and
                debates with groups from various political and social
                backgrounds that can improve integration indicators.
              </p>
              <p>
                UREP aims to support refugees in the exercise of citizenship
                through intercultural mediation in order to guarantee their full
                integration
              </p>
            </div>
            <div class="col-md-4 col-sm-12">
              <h2>Useful Links</h2>
              <ul class="list-unstyled link-list">
                <li>
                  <a ui-sref="about" href="#/about">
                    About us
                  </a>
                  <i class="fa fa-angle-right"></i>
                </li>
                <li>
                  <a ui-sref="portfolio" href="#/portfolio">
                    Portfolio
                  </a>
                  <i class="fa fa-angle-right"></i>
                </li>
                <li>
                  <a ui-sref="products" href="#/products">
                    Latest jobs
                  </a>
                  <i class="fa fa-angle-right"></i>
                </li>
                <li>
                  <a ui-sref="gallery" href="#/gallery">
                    Gallery
                  </a>
                  <i class="fa fa-angle-right"></i>
                </li>
                <li>
                  <a ui-sref="contact" href="#/contact">
                    Contact us
                  </a>
                  <i class="fa fa-angle-right"></i>
                </li>
              </ul>
            </div>
            <div class="col-md-4 col-sm-12 map-img">
              <h2>Contact Us</h2>
            </div>
          </div>

          <div class="nav-box row clearfix">
            <div class="inner col-md-9 clearfix">
              <ul class="footer-nav clearfix">
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#">About</a>
                </li>
                <li>
                  <a href="#">Gallery</a>
                </li>
                <li>
                  <a href="#">Servies</a>
                </li>
                <li>
                  <a href="#">Blog</a>
                </li>
                <li>
                  <a href="#">Contact</a>
                </li>
              </ul>
            </div>
            <div class="donate-link col-md-3">
              <a href="donate.html" class="btn btn-primary">
                <span class="btn-title">Donate Now</span>
              </a>
            </div>
          </div>
        </div>
      </footer>
      <div class="copy">
        <div class="container">
          <a href="https://shakuur.vercel.app/">
            2015 &copy; All Rights Reserved | Designed and Developed by shakuur
            ally
          </a>

          <span>
            <a>
              <i class="fab fa-github"></i>
            </a>
            <a>
              <i class="fab fa-google-plus-g"></i>
            </a>
            <a>
              <i class="fab fa-pinterest-p"></i>
            </a>
            <a>
              <i class="fab fa-twitter"></i>
            </a>
            <a>
              <i class="fab fa-facebook-f"></i>
            </a>
          </span>
        </div>
      </div>
    </body>
  );
}

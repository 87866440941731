import React from "react";
function Footer() {
  return (
    <>
      <footer class="footer">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <h2>About Us</h2>
              <p>
                Develop a multidisciplinary team that helps bring means and
                transparency to integration processes through actions and
                debates with groups from various political and social
                backgrounds that can improve integration indicators.
              </p>
              <p>
                UREP aims to support refugees in the exercise of citizenship
                through intercultural mediation in order to guarantee their full
                integration
              </p>
            </div>
            <div class="col-md-4 col-sm-12">
              <h2>Useful Links</h2>
              <ul class="list-unstyled link-list">
                <li>
                  <a ui-sref="about" href="#/about">
                    About us
                  </a>
                  <i class="fa fa-angle-right"></i>
                </li>
                <li>
                  <a ui-sref="portfolio" href="#/portfolio">
                    Portfolio
                  </a>
                  <i class="fa fa-angle-right"></i>
                </li>
                <li>
                  <a ui-sref="products" href="#/products">
                    Latest jobs
                  </a>
                  <i class="fa fa-angle-right"></i>
                </li>
                <li>
                  <a ui-sref="gallery" href="#/gallery">
                    Gallery
                  </a>
                  <i class="fa fa-angle-right"></i>
                </li>
                <li>
                  <a ui-sref="contact" href="#/contact">
                    Contact us
                  </a>
                  <i class="fa fa-angle-right"></i>
                </li>
              </ul>
            </div>
            <div class="col-md-4 col-sm-12 map-img">
              <h2>Contact Us</h2>
              <address class="md-margin-bottom-40">
                UREP TEAM <br />
                +351 920 017 537 +351920249487 +351 920 022 252 <br />
                Email: geral@urep.pt
                <br /> Our social network: <br />
                www.facebook.com/urep.pt <br />
                twitter.com/UREPpt <br />
                Address: PCT DOS HERÓIS DE ALJUBARROTA 1 R/C ESQ., 2695-704,
                UNIÃO DAS FREGUESIAS DE SANTA IRIA DE AZOIA, UNIAO FREGUESIAS
                SANTA IRIA AZOIA SAO JOAO TALHA BOBADELA, Loures/Lisboa <br />
                <br />
              </address>
            </div>
          </div>

          <div class="nav-box row clearfix">
            <div class="inner col-md-9 clearfix">
              <ul class="footer-nav clearfix">
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#">About</a>
                </li>
                <li>
                  <a href="#">Gallery</a>
                </li>
                <li>
                  <a href="#">Servies</a>
                </li>
                <li>
                  <a href="#">Blog</a>
                </li>
                <li>
                  <a href="#">Contact</a>
                </li>
              </ul>
            </div>
            <div class="donate-link col-md-3">
              <a href="donate.html" class="btn btn-primary">
                <span class="btn-title">Donate Now</span>
              </a>
            </div>
          </div>
        </div>
      </footer>
      <div class="copy">
        <div class="container">
          <a href="https://shakuur.vercel.app/">
            2023 &copy; All Rights Reserved | Designed and Developed by shakuur
            Ally
          </a>

          <span>
            <a>
              <i class="fab fa-github"></i>
            </a>
            <a>
              <i class="fab fa-google-plus-g"></i>
            </a>
            <a>
              <i class="fab fa-pinterest-p"></i>
            </a>
            <a>
              <i class="fab fa-twitter"></i>
            </a>
            <a>
              <i class="fab fa-facebook-f"></i>
            </a>
          </span>
        </div>
      </div>
    </>
  );
}

export default Footer;

import React from "react";
import { Link } from "react-router-dom";
function Nav() {
  return (
    <header class="continer-fluid">
      <div class="header-top">
        <div class="container">
          <div class="row col-det">
            <div class="col-lg-6 d-none d-lg-block">
              <ul class="ulleft">
                <a href="mail:geral@urep.pt">
                  <li>
                    <i class="far fa-envelope"></i>
                    geral@urep.pt <span>|</span>
                  </li>
                </a>
                <a href="tel:+351920022252">
                  <li>
                    <i class="fas fa-phone-volume"></i> +351920022252/920017537
                  </li>
                </a>
              </ul>
            </div>
            <div class="col-lg-6 col-md-12">
              <ul class="ulright">
                <li>
                  <small>Folow Us </small>:
                </li>
                <li>
                  <a href="https://www.facebook.com/urep.pt/">
                    <i class="fab fa-facebook-square"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/UREPpt">
                    <i class="fab fa-twitter-square"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="menu-jk" class="header-bottom">
        <div class="container">
          <div class="row nav-row">
            <div class="col-lg-3 col-md-12 pt-2 pb-2 logo">
              <a href="/" class="row  ">
                <img src="assets/images/logo.png" alt="" />
                <span style={{ fontSize: "40px" }}>Urep</span>
              </a>
            </div>

            <div class="col-lg-9 col-md-12 nav-col">
              <nav class="navbar navbar-expand-lg navbar-light">
                <button
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                  <ul class="navbar-nav">
                    <li class="nav-item">
                      <Link class="nav-link" to="/about">
                        About Us
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link class="nav-link ml-2" to="/services">
                        Services
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link class="nav-link ml-2" to="/gallery">
                        Gallery
                      </Link>
                    </li>

                    <li class="nav-item">
                      <Link class="nav-link" to="/blog">
                        Blog
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link class="nav-link" to="/partners">
                        partners
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link class="nav-link" to="/contact">
                        Contact US
                      </Link>
                    </li>
                  </ul>
                </div>{" "}
                <a class="nav-btn" href="/contact">
                  <button class="btn btn-success mt-0 d-none d-md-block">
                    Donate Now
                  </button>
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Nav;

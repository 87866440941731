import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BlockContent from "@sanity/block-content-to-react";
import client from "../client";

export default function SingleBlog() {
  const [singlePost, setSinglePost] = useState([]);
  const { slug } = useParams();

  useEffect(() => {
    client
      .fetch(
        `*[slug.current == "${slug}"] {
          title,
          slug,
          body,
          publishedAt,
          mainImage {
            asset -> {
              _id,
              url
            },
            alt,
          },
          author->{
            name,
            image {
              asset->{
                url
              }
            }
          }
        }`
      )
      .then((data) => setSinglePost(data[0]))
      .catch(console.error);
  }, [slug]);

  return (
    <div className="container mt-5">
      {singlePost && (
        <div className="row">
          {" "}
          <div className="col-lg-8">
            <article>
              <header className="mb-4  pt-10 m-10 ">
                <h1
                  className="fw-bolder pt-10 m-10 mb-1"
                  style={{
                    margin: "10px",
                    padding: "10px",
                    boxShadow: "2px 2px 10px #ffffff",
                  }}
                >
                  {singlePost.title}
                </h1>
              </header>
              <figure className="mb-4">
                <img
                  src={singlePost.mainImage && singlePost.mainImage.asset.url}
                  alt={singlePost.title}
                  className="img-fluid rounded"
                />
              </figure>
              <section className="mb-5">
                <p className="fs-5 mb-4">
                  {singlePost.body && (
                    <BlockContent
                      blocks={singlePost.body}
                      projectId="n8s4tbq2" // Replace with your Sanity project ID
                      dataset="production" // Replace with your Sanity dataset
                    />
                  )}
                </p>
              </section>
            </article>
          </div>
          <div className="col-lg-4">
            <div
              className="card"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img
                src={singlePost.author?.image?.asset?.url}
                alt={singlePost.author?.name}
                className="card-img-top img-fluid rounded-circle mx-auto mt-4"
                style={{ width: "150px", height: "150px" }}
              />

              <div className="card-body text-center">
                <h5 className="card-title">
                  {" "}
                  Author: {singlePost.author?.name}
                </h5>
                <small>
                  Published at:{" "}
                  {new Date(singlePost.publishedAt).toDateString()}
                </small>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

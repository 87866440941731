import { motion } from "framer-motion";

import React from "react";

export default function About() {
  return (
    <motion.body initial={{ y: -50 }} animate={{ y: 0 }}>
      <div class="page-nav no-margin row">
        <div class="container">
          <div class="row">
            <h2>About Our Charity</h2>
            <ul>
              <li>
                <a href="#">
                  <i class="fas fa-home"></i> Home
                </a>
              </li>
              <li>
                <i class="fas fa-angle-double-right"></i> About Us
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="about-us container-fluid">
        <div class="container">
          <div class="row natur-row no-margin w-100">
            <div class="text-part col-md-6">
              <h2>About Our Charity</h2>
              <p>
                The Union of Refugees in Portugal – UREP was created on May 10,
                2013, formed by refugee communities in Portugal, with the aim of
                promoting and integrating refugees into Portuguese society.
              </p>
              <p>
                União de Refugiados Em Portugal - UREP foi criada a 10 de maio
                de 2013, e oficializada em 31-10-2016. É formada pela comunidade
                de refugiados em Portugal, com o objetivo de promover e integrar
                os refugiados na sociedade portuguesa. Com variados lustres de
                experiência na mediação intercultural com refugiados de
                diferentes origens para a compreensão das suas necessidades
                sempre empenhados em contribuir para o bem-estar de todos os
                refugiados em Portugal. A UREP, através da sua atuação, pretende
                criar a ponte entre as necessidades dos refugiados e colaborar
                com outras instituições responsáveis para o desenvolvimento de
                respostas efetivas, contribuindo para uma estratégia bem
                definida e implementada com sucesso.
              </p>
            </div>
            <div class="image-part col-md-6">
              <div class="about-quick-box row">
                <div class="col-md-6">
                  <div class="about-qcard">
                    <i class="fas fa-user"></i>
                    <p>Becom a Volunteer</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="about-qcard">
                    <i class="fas fa-search-dollar red"></i>
                    <p>Quick Fundrais</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="about-qcard">
                    <i class="fas fa-donate yell"></i>
                    <p>Giv Donation</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="about-qcard">
                    <i class="fas fa-hands-helping blu"></i>
                    <p>Help Someone</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="container-fluid mission-vision">
        <div class="container">
          <div class="row mission">
            <div class="col-md-6 mv-det">
              <h1>Our Mission | Missão</h1>
              <p>
                A União dos Refugiados em Portugal - UREP, visa dar aos
                refugiados um atalho para a sociedade portuguesa. Através da
                nossa comunidade, combinamos refugiados com profissionais,
                organizações e empresas locais na região, com base em
                profissões, habilidades e expertises. A nossa comunidade
                desenvolve o networking adequado a estes cidadãos para que
                realizem as suas ambições profissionais. A nossa missão passa
                por orientar, capacitar, e facilitar a integração no mercado de
                trabalho português, para que construam uma vida de sucesso em
                Portugal.
              </p>
            </div>
            <div class="col-md-6 mv-img">
              <img src="assets/images/events/2.jpeg " alt="" />
            </div>
          </div>
          <div class="row vision">
            <div class="col-md-6 mv-img">
              <img src="assets/images/events/1.jpeg" alt="" />
            </div>
            <div class="col-md-6 mv-det">
              <h1> Our vision | Visão</h1>
              <p>
                A União dos Refugiados em Portugal - UREP visa apoiar os
                refugiados no exercício da cidadania através da mediação
                intercultural de forma a garantir a sua plena integração,
                providenciando o acesso a meios de inclusão social que os
                dignifiquem em harmonia com a legalidade, acreditando na unidade
                e no trabalho interdisciplinar como resposta aos desafios, que
                exigem um acompanhamento direto para solucionar a panóplia de
                problemáticas sociais que as barreiras quotidianas lhes antepõem
                como obstáculos e que exigem solução constante.
              </p>
            </div>
          </div>
        </div>
      </section>
    </motion.body>
  );
}
